.suggestions{
    background-color: white;
    position: absolute;
    width: 20%;
    max-height: 300px;
    overflow-y: scroll;


}
.sugg-option{
padding: 10px;
}

.sugg-option:hover{
    cursor: pointer;
background-color: rgba(128, 128, 128, 0.2);
}