body {
  overflow-x: hidden;
}

/*** CUSTOM CSS ****/

:root {
  --secondary-color: #0eaeba;
  --main-color: #0eaeba;
  --hovred-main-color: #0b848d;
  --danger-color: #f6a409;
  --danger-color-hover: #e49709;
}

.text-main {
  color: var(--main-color) !important;
}
.btn-main {
  background-color: var(--main-color) !important;
  color: #fff;
}

.btn-orangered {
  background-color: var(--danger-color) !important;
  color: #fff;
}
.btn-orangered:hover {
  background-color: var(--danger-color-hover) !important;
  color: #fff;
}

.btn-main-outline {
  border: 1px solid var(--secondary-color);
  background-color: #fff;
}

.btn-main:hover {
  background-color: var(--hovred-main-color) !important;
  color: #fff;
}

.bg-main,
.alert-main {
  background-color: var(--main-color) !important;
  color: #fff;
}

.bg-danger,
.alert-danger {
  background-color: var(--danger-color) !important;
  color: #fff;
}

.form-switch .form-check-input:checked {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.notification-success {
  background-color: var(--main-color) !important;
}

.notification-error {
  background-color: var(--danger-color) !important;
}

.notification-success .title,
.notification-error .title {
  color: white !important;
}

a.tooltipCustom[title] {
  word-wrap: break-word;
}

.rdp-day_selected {
  background-color: #0eaeba !important;
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #0eaeba !important;
  --rdp-background-color: #e7edff !important;
  --rdp-accent-color-dark: #0eaeba !important;
  --rdp-background-color-dark: #0eaeba !important;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
}
