
@mixin tablet-and-up {
  @media screen and (min-width: 769px) { @content; }
}
@mixin mobile-and-up {
  @media screen and (min-width: 601px) { @content; }
}
@mixin tablet-and-down  {
  @media screen and (max-width: 768px) { @content; }
}
@mixin mobile-only {
  @media screen and (max-width: 600px) { @content; }
}


.TimeLine ul, .TimeLine li{
  list-style: none;
  padding: 0;
}


.TimeLine{
  scroll-behavior: smooth;
  background: white;
  padding:1rem 2.5rem;
  border-radius: 8px;
  max-height: 100vh;
  overflow-y: scroll;
}
.TimeLine h1{
  font-size: 1.1rem;
  font-family: sans-serif;
}
.TimeLine .sessions{
  margin-top: 0;
  border-radius: 12px;
  position: relative;
}
.TimeLine li{
  padding-bottom: .7rem;
  border-left: 1px solid #9f9f9f;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  &:last-child{
    border: 0px;
    padding-bottom: 0;
  }
  &:before{
    content: '';
    width: 15px;
    height: 15px;
    background: #3498db;
    //border: 1px solid #3498db;
    //box-shadow: 3px 3px 0px #bab5f8;
    //box-shadow: 3px 3px 0px #bab5f8;
    border-radius: 50%;
    position: absolute;
    left: -8px;
    top: 0px;
  }
}
.TimeLine .time{
  color: #2a2839;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  @include mobile-and-up{
    font-size: .9rem;
  }
  @include mobile-only{
    margin-bottom: .3rem;
    font-size: 0.85rem;
  }

}
.TimeLine h1{
  color: #3498db;
  font-family: sans-serif;
  line-height: 1.5;
  margin-top:0.4rem;
  @include mobile-only{
    font-size: .9rem;
  }
}